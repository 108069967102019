import moment from "moment-timezone";

export const fileSize = (bytes) => {
  var sizes = ["Bytes", "KB", "MB", "GB", "TB"];
  if (bytes === 0) return "0 Byte";
  var i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
  return Math.round(bytes / Math.pow(1024, i), 2) + " " + sizes[i];
};

export const niceDate = (value) => {
  return moment(value).format("L LT");
};

export const unixDate = (value) => {
  return moment.unix(value).format("L LT");
};

export default {
  fileSize,
  niceDate,
};
